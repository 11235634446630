const inViewport = (entries, observer) => {
  entries.forEach(entry => {
    entry.target.classList.toggle('section--visible', entry.isIntersecting);
  });
};

const Obs = new IntersectionObserver(inViewport);
const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

// Attach observer to every .section element:
const ELs_inViewport = document.querySelectorAll('.section');
ELs_inViewport.forEach(EL => {
  Obs.observe(EL, obsOptions);
});
