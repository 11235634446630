function resizeIFrameToFitContent(iFrame, adjustHeight) {
  const containerWidth = iFrame.parentElement.clientWidth;

  iFrame.width  = containerWidth + 'px';
  if (adjustHeight) {
    iFrame.height = Math.round(containerWidth * 9 / 16) + 'px';
  }
}

const videoSelector = '.iframe__video';
const audioSelector = '.iframe__audio';

window.addEventListener('DOMContentLoaded', function(e) {
  let iFrames = document.querySelectorAll(videoSelector);
  iFrames.forEach((iFrame) => {
    resizeIFrameToFitContent(iFrame, true);
  });

  /* iFrames = document.querySelectorAll(audioSelector);
  iFrames.forEach((iFrame) => {
    resizeIFrameToFitContent(iFrame, false);
  }); */
});


window.addEventListener('resize', function(e) {
  let iFrames = document.querySelectorAll(videoSelector);
  iFrames.forEach((iFrame) => {
    resizeIFrameToFitContent(iFrame, true);
  });

  /* iFrames = document.querySelectorAll(audioSelector);
  iFrames.forEach((iFrame) => {
    resizeIFrameToFitContent(iFrame, false);
  }); */
});
